import React from "react";
import { graphql } from "gatsby";
import { Container, Heading, SimpleGrid, Image, Link } from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
// import usePrismicFinancialEducation from "../../hooks/usePrismicFinancialEducation";
import usePrismicAboutUs from "../../hooks/usePrismicAboutUs";

const AllInformationBookletsAboutUs = () => {
  const { language } = useTranslation();
  const data = usePrismicAboutUs();
  return (
    <Main>
      <Container maxW="7xl" mb="13.5rem">
        <Seo
          title={data.title}
          description={data.title}
          link={`https://www.fsm.am/${language}/{t("news")}`}
        />
        <SmallNav>
          <Link to="/about-us">{data.title}</Link>
        </SmallNav>
        <Heading
          fontFamily="Mardoto"
          as="h2"
          fontSize={{ base: "2xl", md: "3xl" }}
          mt="1.25rem"
          mb="3.81rem"
        >
          {data.title}
        </Heading>
        <SimpleGrid columns={{ base: "1", md: "3" }} spacing={2}>
          {data.bookletsAboutUs?.map((item, idx) => (
            <Link
              key={idx}
              href={item.link}
              target="_blank"
              _focus={{ boxShadow: "none" }}
            >
              <Image
                h="70%"
                w="100%"
                objectFit="cover"
                borderRadius="24px"
                src={item.imageaboutusbooklets}
              />
            </Link>
          ))}
        </SimpleGrid>
      </Container>
    </Main>
  );
};

export default AllInformationBookletsAboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
